export function getCurrentDate() {
    var date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    /*let hh = date.getHours();
    let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();*/
    return yy + '-' + mm + '-' + dd;
}
export function getCurrentDateTimeq() {
    var date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1< 10 ? '0' + (date.getMonth() + 1)  : date.getDate() +1;
    let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return yy + '-' + mm + '-' + dd;
}
export function getCurrentTimes() {
    var date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1< 10 ? '0' + (date.getMonth() + 1)  : date.getDate() +1;
    // let dd = date.getDate();
    let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    // let hh = date.getHours();
    let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return yy + '-' + mm + '-' + dd + ' ' + hh +":"+ mf + ":" + ss;
}


export function getCurrentDateTime() {
    var date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1< 10 ? '0' + (date.getMonth() + 1)  : date.getDate() +1;
    // let dd = date.getDate();
    let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return yy + '-' + mm + '-' + dd  + " " + '00'+':'+'00'+':'+'00';
}

// 获取当前日期前后多少天的日期，多少天前传正数，多少天后传负数，今天传0(num)
//  num为传入的数字， time为传入的指定日期，如果time不传，则默认为当前时间


export function getBeforeDateTime(num,time) {
    let n = num;
    let d = '';
    if(time) {
        d = new Date(time);
    } else {
        d = new Date();
    }
    let year = d.getFullYear();
    let mon = d.getMonth() + 1;
    let day = d.getDate();
    if(day <= n) {
        if(mon > 1) {
            mon = mon - 1;
        } else {
            year = year - 1;
            mon = 12;
        }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day) + " " + '00'+':'+'00'+':'+'00';
}

/**
 * 把秒转为日期，年月日时分秒
 */
export function secondToDate(second) {
  let time = new Date(second*1000);
  let year = time.getFullYear();
  let mon = time.getMonth() + 1;
  let day = time.getDate();
  let hh = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
  let mf = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
  let ss = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
  return year + "-" + mon + "-" + day + " " + hh + ":" + mf + ":" + ss;
}

export function getCurrentTimess() {
    var date = new Date();
    let yy = date.getFullYear();
    let mm = date.getMonth() + 1< 10 ? '0' + (date.getMonth() + 1)  : date.getDate() +1;
    // let dd = date.getDate();
    let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    // let hh = date.getHours();
    let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return hh +":"+ mf;
}

/**
 * 会话列表显示的时间
 *  如果是同一天，返回 小时和分钟
 *  如果不是同一天，返回 年月日
 * @param second 秒
 */
export function getMsgSendTime(second) {
  let time = new Date(second*1000);
  let currentTime = new Date();
  if (isSameDay(time.getTime(), currentTime.getTime())) {
      let hh = time.getHours() < 10 ? time.getHours() : time.getHours();
      let mf = time.getMinutes() < 10 ? time.getMinutes() : time.getMinutes();
      return hh + ":" + mf;
  } else {
      let mm = time.getMonth() + 1< 10 ?  (time.getMonth() + 1)  : time.getDate() +1;
      let dd = time.getDate() < 10 ?  time.getDate() : time.getDate();
      return mm + '-' + dd ;
  }
}

/**
 * 判断两个日期是不是同一天
 *
 * @param date1
 * @param date2
 * @returns {boolean}
 */
export function isSameDay(date1, date2) {
  return (new Date(date1).setHours(0, 0, 0, 0)) === (new Date(date2).setHours(0, 0, 0, 0));
}
