<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">好友申请</div>
      <div class="topRight">
        <img src="../../image/quanz/jhy.png" alt="" @click="goSeekFriend()" />
      </div>
    </div>
    <div class="searBox">
      <img src="../../image/quanz/s.png" alt="" />请输入成员昵称
    </div>
    <div class="container" v-for="item in applyFriends" :key="item.id">
      <div class="fansHear" @click="detail(item)">
        <img :src="item.fromHeadImg">
      </div>
      <div class="nameBrief">
        <div>{{ item.fromName	 }}</div>
        <div>{{ item.msg }}</div>
      </div>
      <div class="gzStyle">
        <img src="../../image/quanz/js.png" alt="" v-if="item.type == 'Pendency_Type_ComeIn'" @click="pass(item.fromId, item.fromName)"/>
        <img src="../../image/quanz/dtg.png" alt="" v-if="item.type == 'Pendency_Type_SendOut'"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getApplyFriends} from "@/api/user";
import {secondToDate} from "@/utils/CommonUtils"

export default {
  data() {
    return {
      applyFriends: [],
      bakApplyFriends: [],
      pageNum: 1,
      pageSize: 10,
      timReady: false,
      thisUserId: this.$store.state.user.id,
    };
  },
  mounted() {
    let user = this.$store.state.user;
    if (user.circleAuth == 2) {
      let tis = this;
      let onFriendApplicationListUpdated = function(event) {
        console.log("onFriendApplicationListUpdated", event)
        // friendApplicationList - 好友申请列表 - [FriendApplication]
        // unreadCount - 好友申请的未读数
        let { friendApplicationList, unreadCount } = event.data;
        // 发送给我的好友申请（即别人申请加我为好友）
        // const applicationSentToMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TencentCloudChat.TYPES.SNS_APPLICATION_SENT_TO_ME);
        // 我发送出去的好友申请（即我申请加别人为好友）
        // const applicationSentByMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TencentCloudChat.TYPES.SNS_APPLICATION_SENT_BY_ME);
        if (friendApplicationList.length > 0) {
          for (let index in friendApplicationList) {

            let applyFriend = {};
            let friend = friendApplicationList[index];
            let num = tis.applyFriends.filter(x => x.fromId === friend.userID).length
            if (num > 0) {
              continue;
            }
            // 发送给我的好友申请（即别人申请加我为好友）
            if (friend.type ===  tis.Tim.TYPES.SNS_APPLICATION_SENT_TO_ME) {
              applyFriend.fromId = friend.userID;
              applyFriend.fromHeadImg = friend.avatar;
              applyFriend.fromName =friend.nick;
              applyFriend.msg = friend.wording;
              applyFriend.time = secondToDate(friend.time);
              applyFriend.type = friend.type;
            }
            // 我发送出去的好友申请（即我申请加别人为好友）
            else if (friend.type ===  tis.Tim.TYPES.SNS_APPLICATION_SENT_BY_ME) {
              applyFriend.fromId = friend.userID;
              applyFriend.fromHeadImg = friend.avatar;
              applyFriend.fromName =friend.nick;
              applyFriend.msg = friend.wording;
              applyFriend.time = secondToDate(friend.time);
              applyFriend.type = friend.type;
            }
            tis.applyFriends.push(applyFriend);
          }
        }
      };
      this.tim.on(this.Tim.EVENT.FRIEND_APPLICATION_LIST_UPDATED, onFriendApplicationListUpdated, this);
      if (!this.tim.isReady()) {
        //SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          // console.log("address-onSdkReady", event)
          tis.getFriendApply();
          tis.setFriendApplicationRead();
        };
        this.tim.on(tis.Tim.EVENT.SDK_READY, onSdkReady);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          // console.log("address-onSdkNotReady", event)
          tis.imLogin();
        };
        this.tim.on(tis.Tim.EVENT.SDK_NOT_READY, onSdkNotReady);
        tis.imLogin();
      } else {
        this.getFriendApply();
        this.setFriendApplicationRead();
      }
    }
  },
  destroyed() {
    // this.tim.logout();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    detail(detail) {
      this.$router.push({ path: "/applyDetail", query: {detail: JSON.stringify(detail)} });
    },
    // 查找好友
    goSeekFriend() {
      this.$router.push({ path: "/seekFriend" });
    },
    imLogin() {
      let user = this.$store.state.user;
      console.log(this.timReady)
      if (user.circleAuth == 2 && !this.timReady) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    // 获取好友申请列表
    getFriendApply() {
      let tis = this;
      getApplyFriends(tis.pageNum, tis.pageSize).then(res => {
        console.log("getApplyFriends", res)
        if (res.data.data.items.length > 0) {
          for (let index in res.data.data.items) {
            let friend = {};
            if (this.thisUserId === res.data.data.items[index].fromId) {
              friend.fromId = res.data.data.items[index].fromId;
              friend.fromHeadImg = res.data.data.items[index].fromHeadImg;
              friend.fromName =res.data.data.items[index].fromName;
              friend.msg = res.data.data.items[index].msg;
              friend.time = res.data.data.items[index].createTime;
              friend.type = tis.Tim.TYPES.SNS_APPLICATION_SENT_TO_ME;
            } else {
              friend.fromId = res.data.data.items[index].toId;
              friend.fromHeadImg = res.data.data.items[index].toHeadImg;
              friend.fromName =res.data.data.items[index].toName;
              friend.msg = res.data.data.items[index].msg;
              friend.time = res.data.data.items[index].createTime;
              friend.type = tis.Tim.TYPES.SNS_APPLICATION_SENT_BY_ME;
            }
            tis.applyFriends.push(friend);
          }
        }
      })
      // let promise = this.tim.getFriendApplicationList();
      // promise.then(function(imResponse) {
      //   console.log("getFriendApplicationList", imResponse)
      //   // friendApplicationList - 好友申请列表 - [FriendApplication]
      //   // FriendApplication 数据结构详情请参考 https://web.sdk.qcloud.com/im/doc/v3/zh-cn/FriendApplication.html
      //   // unreadCount - 好友申请的未读数
      //   // const { friendApplicationList, unreadCount } = imResponse.data;
      // });
    },
    // 同意好友申请
    pass(userID, fromName) {
      let promise = this.tim.acceptFriendApplication({
        userID: userID + "",
        remark: fromName,
        type: this.Tim.TYPES.SNS_APPLICATION_AGREE_AND_ADD
      });
      promise.then(function(imResponse) {
        // 同意好友成功后，SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
        // 发送文本消息，Web 端与小程序端相同
        // 1. 创建消息实例，接口返回的实例可以上屏
        let message = this.tim.createTextMessage({
          to: userID + '',
          conversationType: this.Tim.TYPES.CONV_C2C,
          // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
          // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
          payload: {
            text: "您好！"
          },
          // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
          needReadReceipt: true
          // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
          // cloudCustomData: 'your cloud custom data'
        });
        // 2. 发送消息
        let promise = this.tim.sendMessage(message);
        promise.then(function(imResponse) {
          // 发送成功
          // console.log("sendMessage",imResponse);
          // console.log(tis.messageList)
          this.messageList.push(imResponse.data.message)
          this.message = '';
        }).catch(function(imError) {
          // 发送失败
          console.warn('sendMessage error:', imError);
        });
      }).catch(function(imError) {
        console.warn('acceptFriendApplication error:', imError);
      });
    },
    // 设置好友申请已读
    setFriendApplicationRead() {
      // 上报好友申请已读
      let promise = this.tim.setFriendApplicationRead();
      promise.then(function(imResponse) {
        // 已读上报成功
      }).catch(function(imError) {
        console.warn('setFriendApplicationRead error:', imError);
      });
    },

  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.topRight img {
  display: flex;
  width: 172px;
  height: 56px;
  margin-left: 106px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 34px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.container {
  width: 686px;
  height: 144px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.fansHear {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  //background: red;
}
.fansHear img {
  //width: 100%;
  height: 100%;
  //border-radius: 50%;
}
.nameBrief {
  width: 470px;
  margin-left: 20px;
}
.nameBrief div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.nameBrief div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.gzStyle img {
  width: 144px;
  height: 56px;
}
</style>
